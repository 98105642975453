import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'

import {
  useLazyFetchUserQuery,
} from '~/store/api'
import { EXPERIMENTS } from '~/constants'
import { useExperiment } from '~/hooks/useExperiment'

import { ROUTES } from '~/pages/constants'
import BankAccountLink from '~/features/BankAccountManagement/Link'
import Loader from '~/components/ui/Loader'

import { BANK_LINK_REASONS } from '../constants'

const LinkAccountV6 = props => {
  const {
    accounts,
    isLoading,
    onLoading,
    onSuccess,
    btnText,
    buttonClassName,
    onClick,
    redirect,
    plaidItemUuid,
    differentBankAccount,
  } = props

  const navigate = useNavigate()

  const {
    enabled: isPreMembershipPlanEducationEnabled,
    isLoaded: isPreMembershipPlanEducationLoaded,
  } = useExperiment(EXPERIMENTS.PRE_MEMBERSHIP_PLAN_EDUCATION)

  const [fetchUser] = useLazyFetchUserQuery()

  const handleSuccess = useCallback(() => {
    onSuccess()
    fetchUser()
    if (redirect) {
      isPreMembershipPlanEducationEnabled
        ? navigate(ROUTES.CHOOSE_SUBSCRIPTION)
        : navigate(ROUTES.SUBSCRIPTION)
    }
  }, [onSuccess, navigate, isPreMembershipPlanEducationEnabled, redirect, fetchUser])

  return (
    <>
      {isLoading || !isPreMembershipPlanEducationLoaded ? (
        <Loader data-testid="stellar-link-bank-accounts-loader-v6" size="small" />
      ) : (
        <BankAccountLink
          accounts={accounts}
          onLoading={onLoading}
          onSuccess={handleSuccess}
          btnText={btnText}
          btnClassName={buttonClassName}
          onClick={onClick}
          variant="v6"
          plaidItemUuid={plaidItemUuid}
          reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
          differentBankAccount={differentBankAccount}
        />
      )}
    </>
  )
}
LinkAccountV6.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  redirect: PropTypes.bool,
  plaidItemUuid: PropTypes.string,
  differentBankAccount: PropTypes.bool,
}
LinkAccountV6.defaultProps = {
  accounts: [],
  btnText: 'Add bank +',
  buttonClassName: '',
  onClick: () => {},
  redirect: false,
  plaidItemUuid: '',
  differentBankAccount: false,
}

const Memoized = React.memo(LinkAccountV6)
export default Memoized
