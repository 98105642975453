import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ChimeModal from '~/components/modals/Chime'
import {
  selectIsMobile,
} from '~/store/modules/dimensions/selectors'
import {
  NO_BANK_CLICKED,
} from '~/services/segment'
import { useSegmentEvents } from '~/hooks/useSegmentEvents'

import AddDifferentAccount from './Plaid/AddDifferentAccount'
import LinkAccountV2 from './variants/V2'
import LinkAccountV3 from './variants/V3'
import LinkAccountV4 from './variants/V4'
import LinkAccountV5 from './variants/V5'
import LinkAccountV6 from './variants/V6'
import LinkAccountV7 from './variants/V7'
import { LINK_ACCOUNT_VARIANTS } from './constants'

const LINK_ACCOUNT_COMPONENTS = {
  [LINK_ACCOUNT_VARIANTS.V2]: LinkAccountV2,
  [LINK_ACCOUNT_VARIANTS.V3]: LinkAccountV3,
  [LINK_ACCOUNT_VARIANTS.V4]: LinkAccountV4,
  [LINK_ACCOUNT_VARIANTS.V5]: LinkAccountV5,
  [LINK_ACCOUNT_VARIANTS.V6]: LinkAccountV6,
  [LINK_ACCOUNT_VARIANTS.V7]: LinkAccountV7,
}

const BankAccountManagement = props => {
  const {
    variant,
    onSuccess,
    isZeroLimitUser,
    forceMobile,
    btnText,
    redirect,
    buttonClassName,
    onClick,
    plaidItemUuid,
    className,
    disabled,
    btnClassName,
    loaderClassName,
    forceUsePlaid,
    differentBankAccount,
    onLoading,
  } = props

  const isMobile = useSelector(selectIsMobile)
  const trackNoBankClicked = useSegmentEvents([NO_BANK_CLICKED])

  const [accounts, setAccounts] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [hasSucceeded, setHasSucceeded] = useState(false)
  const [isChimeModalOpen, setIsChimeModalOpen] = useState(false)
  const handleChimeModalOpen = useCallback(() => {
    setIsChimeModalOpen(true)
    trackNoBankClicked()
  }, [setIsChimeModalOpen, trackNoBankClicked])
  const handleChimeModalClose = useCallback(() => setIsChimeModalOpen(false), [setIsChimeModalOpen])

  const handleLoading = useCallback(() => {
    onLoading()
    setIsLoading(true)
  }, [onLoading, setIsLoading])

  const handleSuccess = useCallback(newAccounts => {
    setIsLoading(false)
    setAccounts(newAccounts)
    onSuccess()
    setHasSucceeded(true)
  }, [setIsLoading, onSuccess, setAccounts, setHasSucceeded])

  const LinkAccountComponent = LINK_ACCOUNT_COMPONENTS[variant]

  return (
    <>
      {isZeroLimitUser ? (
        <AddDifferentAccount
          accounts={accounts}
          isLoading={isLoading}
          hasSucceeded={hasSucceeded}
          onLoading={handleLoading}
          onSuccess={handleSuccess}
        />
      ) : (
        <>
          {LinkAccountComponent ? (
            <LinkAccountComponent
              accounts={accounts}
              btnText={btnText}
              buttonClassName={buttonClassName}
              btnClassName={btnClassName}
              className={className}
              onClick={onClick}
              redirect={redirect}
              isMobile={forceMobile || isMobile}
              isLoading={isLoading}
              plaidItemUuid={plaidItemUuid}
              onLoading={handleLoading}
              onSuccess={handleSuccess}
              onChimeModalOpen={handleChimeModalOpen}
              disabled={disabled}
              loaderClassName={loaderClassName}
              forceUsePlaid={forceUsePlaid}
              differentBankAccount={differentBankAccount}
            />
          ) : null}
        </>
      )}

      <ChimeModal
        show={isChimeModalOpen}
        onClose={handleChimeModalClose}
      />
    </>
  )
}
BankAccountManagement.propTypes = {
  variant: PropTypes.string,
  onSuccess: PropTypes.func,
  isZeroLimitUser: PropTypes.bool,
  forceMobile: PropTypes.bool,
  btnText: PropTypes.string,
  redirect: PropTypes.bool,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  plaidItemUuid: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  btnClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  forceUsePlaid: PropTypes.bool,
  differentBankAccount: PropTypes.bool,
  onLoading: PropTypes.func,
}
BankAccountManagement.defaultProps = {
  variant: 'v2',
  onSuccess: () => {},
  isZeroLimitUser: false,
  forceMobile: false,
  btnText: 'Connect bank',
  redirect: true,
  buttonClassName: '',
  onClick: () => {},
  plaidItemUuid: '',
  className: '',
  btnClassName: '',
  disabled: false,
  loaderClassName: '',
  forceUsePlaid: false,
  differentBankAccount: false,
  onLoading: () => {},
}

export default BankAccountManagement
