import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import Loader from '~/components/ui/Loader'

import BankAccountLink from '~/features/BankAccountManagement/Link'
import { BANK_LINK_REASONS } from '../constants'

const LinkAccountV5 = props => {
  const {
    accounts,
    isLoading,
    onLoading,
    onSuccess,
    btnText,
    disabled,
    buttonClassName,
    loaderClassName,
    differentBankAccount,
  } = props

  const handleSuccess = useCallback(() => {
    onSuccess()
  }, [onSuccess])

  return (
    <>
      {isLoading ? (
        <Loader
          data-testid="stellar-link-bank-accounts-loader-v5"
          className={loaderClassName}
        />
      ) : (
        <BankAccountLink
          accounts={accounts}
          onLoading={onLoading}
          onSuccess={handleSuccess}
          variant="v5"
          reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
          btnText={btnText}
          disabled={disabled}
          btnClassName={buttonClassName}
          differentBankAccount={differentBankAccount}
        />
      )}
    </>
  )
}
LinkAccountV5.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  disabled: PropTypes.bool,
  buttonClassName: PropTypes.string,
  loaderClassName: PropTypes.string,
  differentBankAccount: PropTypes.bool,
}
LinkAccountV5.defaultProps = {
  accounts: [],
  btnText: 'Connect bank',
  buttonClassName: '',
  disabled: false,
  loaderClassName: '',
  differentBankAccount: false,
}

const Memoized = React.memo(LinkAccountV5)
export default Memoized
