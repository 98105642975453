import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import {
  useLazyFetchUserQuery,
} from '~/store/api'

import BankAccountLink from '~/features/BankAccountManagement/Link'
import Loader from '~/components/ui/Loader'
import { BANK_LINK_REASONS } from '../constants'

// This variant serves as a fallback option link,
// allowing users to connect a Plaid account when experiencing issues with MX

const LinkAccountV7 = props => {
  const {
    accounts,
    isLoading,
    onLoading,
    onSuccess,
    btnText,
    buttonClassName,
    onClick,
    plaidItemUuid,
    forceUsePlaid,
    differentBankAccount,
  } = props

  const [fetchUser] = useLazyFetchUserQuery()

  const handleSuccess = useCallback(() => {
    fetchUser()
    onSuccess()
  }, [fetchUser, onSuccess])

  return (
    <>
      {isLoading ? (
        <Loader data-testid="stellar-link-bank-accounts-loader-v7" size="medium" />
      ) : (
        <BankAccountLink
          accounts={accounts}
          onLoading={onLoading}
          onSuccess={handleSuccess}
          btnText={btnText}
          btnClassName={buttonClassName}
          onClick={onClick}
          variant="v7"
          plaidItemUuid={plaidItemUuid}
          reason={BANK_LINK_REASONS.ACCOUNT_SETUP}
          forceUsePlaid={forceUsePlaid}
          differentBankAccount={differentBankAccount}
        />
      )}
    </>
  )
}
LinkAccountV7.propTypes = {
  accounts: PropTypes.arrayOf(PropTypes.shape({})),
  isLoading: PropTypes.bool.isRequired,
  onLoading: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  btnText: PropTypes.string,
  buttonClassName: PropTypes.string,
  onClick: PropTypes.func,
  plaidItemUuid: PropTypes.string,
  forceUsePlaid: PropTypes.bool,
  differentBankAccount: PropTypes.bool,
}
LinkAccountV7.defaultProps = {
  accounts: [],
  btnText: 'Add bank +',
  buttonClassName: '',
  onClick: () => {},
  plaidItemUuid: '',
  forceUsePlaid: false,
  differentBankAccount: false,
}

const Memoized = React.memo(LinkAccountV7)
export default Memoized
